import $ from "jquery";

declare global {
    // Declare additional functionality on window interface.
    interface Window {
        // eslint-disable-line @typescript-eslint/naming-convention
        $?: JQueryStatic;
        jQuery?: JQueryStatic;
    }
}

// Publish references in global namespace now that jQuery is wrapped in Webpack module.
(() => {
    window.$ = $;
    window.jQuery = $;
})();
