﻿/**
 * jQuery extension for general custom methods.
 */

(function($) {
    
    if(!$) {
        return;
    }

    /**
     * Recursively cleans whitespaces inside the element
     */
    $.fn.cleanWhitespace = function () {
        this.contents().filter(
            function() {
                if (this.nodeType !== Node.TEXT_NODE) {
                    $(this).cleanWhitespace();
                    return false;
                } else {
                    this.textContent = $.trim(this.textContent);
                    return !/\S/.test(this.nodeValue);
                }
            }).remove();
        return this;
    }

    /**
     * Set of default events covered by jQuery without plugins.
     */
    var defaultEvents = [
        "BeforeUnload",
        "Blur",
        "Change",
        "Click",
        "ContextMenu",
        "DblClick",
        "Error",
        "Focus",
        "FocusIn",
        "FocusOut",
        "KeyDown",
        "KeyPress",
        "KeyUp",
        "Load",
        "MouseDown",
        "MouseEnter",
        "MouseLeave",
        "MouseMove",
        "MouseOut",
        "MouseOver",
        "MouseUp",
        "OrientationChange",
        "Resize",
        "Scroll",
        "Select",
        "Submit",
        "Unload"
    ];

    /**
     * Adds alias bind- and unbind- methods for connecting and disconnecting
     * event handlers according to provided event type (key).
     */
    function createMethod(key) {
        
        function updateNs(namespace) {
            var ns = $.trim(namespace || "");
            if(ns && ns.substring(0, 1) === ".") {
                ns = ns.substring(1);
            }
            return ns;
        }

        var methodName = "bind" + key;
        if(typeof $.fn[methodName] !== "function") {
            $.fn[methodName] = function (handler, namespace, one) {
                var eventType = key.toLowerCase(),
                    ns = updateNs(namespace),
                    $bindMethod = one ? "one" : "on";
                if(ns) {
                    eventType += "." + ns;
                }
                this[$bindMethod](eventType, handler);
                return this;
            }
        }
        methodName = "unbind" + key;
        if(typeof $.fn[methodName] !== "function") {
            $.fn[methodName] = function (handler, namespace) {
                var eventType = key.toLowerCase(),
                    ns = updateNs(namespace);
                if(ns) {
                    eventType += "." + ns;
                }
                this.off(eventType, handler);
                return this;
            }
        }
    }

    /**
     * Adds alias methods for connecting and disconnecting event handlers according to current events list.
     * For example for .on("click", handler) it creates more type safe .bindClick(handler) alias method.
     * @param events Optional array of event name strings in camelcase and starting with big letter.
     */
    $.createEventMethods = function (events) {
        var updatedEvents = defaultEvents;
        if($.isArray(events)) {
            updatedEvents = updatedEvents.concat(events);
        }
        if(!updatedEvents || !updatedEvents.length) {
            return;
        }
        for(var key in updatedEvents) {
            if(updatedEvents.hasOwnProperty(key)) {
                createMethod(updatedEvents[key]);
            }
        }
    }

    $.createEventMethods();

})(window.jQuery);
